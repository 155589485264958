<template>
    <div class="popover__container">
        <div>
            <button v-if="overlay === false"
                    type="button"
                    class="button popover__button"
                    @click="openPopOverlay('title')"
            >
                change title
            </button>
            <title-overlay v-if="overlays.title"
                           :title="lesson.title"
                           :on-submit="onTitleSubmit"
                           :on-cancel="onTitleCancel"
            />
        </div>
        <div>
            <button v-if="overlay === false"
                    type="button"
                    class="button popover__button"
                    @click="openPopOverlay('description')"
            >
                change description
            </button>
            <description-overlay v-if="overlays.description"
                                 :description="lesson.description"
                                 :on-submit="onDescriptionSubmit"
                                 :on-cancel="onDescriptionCancel"
            />
        </div>
        <div>
            <button v-if="overlay === false"
                    type="button"
                    class="button popover__button"
                    @click="openPopOverlay('components')"
            >
                change components
            </button>
            <components-overlay v-if="overlays.components"
                                :lesson="lesson"
                                :on-submit="onComponentsSubmit"
                                :on-cancel="onComponentsCancel"
            />
        </div>
    </div>
</template>

<script>
import TitleOverlay from '@/components/lessons/overlays/TitleOverlay.vue';
import DescriptionOverlay from '@/components/lessons/overlays/DescriptionOverlay.vue';
import ComponentsOverlay from '@/components/lessons/overlays/ComponentsOverlay.vue';

export default {
    components: {
        TitleOverlay,
        DescriptionOverlay,
        ComponentsOverlay,
    },
    props: {
        onSubmit: {
            type: Function,
            default: () => {},
        },
        lesson: {
            type: Object,
            default: () => ({
                title: '',
                description: '',
            }),
        },
    },
    data() {
        return {
            overlays: {
                title: false,
                description: false,
                components: false,
            },
            overlay: false,
        };
    },
    methods: {
        resetOverlay(overlay) {
            this.overlays[overlay] = false;
            this.overlay = false;
        },

        setOverlay(overlay) {
            this.overlays[overlay] = true;
            this.overlay = true;
        },

        openPopOverlay(overlay) {
            if (overlay === 'title') {
                this.setOverlay(overlay);
                return;
            }
            if (overlay === 'description') {
                this.setOverlay(overlay);
                return;
            }
            if (overlay === 'components') {
                this.setOverlay(overlay);
            }
        },

        async onTitleSubmit({ title }) {
            await this.onSubmit({ title });
            this.resetOverlay('title');
        },

        async onDescriptionSubmit({ description }) {
            await this.onSubmit({ description });
            this.resetOverlay('description');
        },

        async onComponentsSubmit({ type, layoutComponents }) {
            await this.onSubmit({ type, layoutComponents });
            this.resetOverlay('components');
        },

        onTitleCancel() {
            this.resetOverlay('title');
        },
        onDescriptionCancel() {
            this.resetOverlay('description');
        },
        onComponentsCancel() {
            this.resetOverlay('components');
        },
    },
};
</script>

<style lang="scss" scoped>
.popover{
    &__container{
        @apply w-full;
        @apply mt-3;
        @apply py-3;
        @apply px-0;
        @apply border-b;
        @apply border-solid;
        @apply border-gray-300;
        height: max-content;
    }
    &__button{
        @apply w-full;
        @apply h-8;
        border-radius: unset;
        padding: unset;
        @apply text-sm;
        @apply bg-white;
        @apply text-gray-700;
        @apply flex;
        @apply items-center;
        @apply justify-start;
        @apply pl-3;
    }
}
</style>
