<template>
    <form class="form w-4/5">
        <div class="form__control">
            <label for="title" class="form__label">
                Title
            </label>
            <input id="title"
                   v-model.trim="newLesson.title"
                   class="form__input"
                   type="text"
                   aria-placeholder="my awesome lesson"
            >
        </div>
        <div class="form__control">
            <label for="title" class="form__label">
                Description
            </label>
            <textarea id="title"
                      v-model.trim="newLesson.description"
                      class="form__textarea"
                      type="text"
                      aria-placeholder="my awesome lesson"
            />
        </div>
        <div class="form__control">
            <label class="form__label">
                Components
            </label>
            <div class="form__components">
                <check-box name="Does the lesson require a simulator ?"
                           :meta="checkBoxMetadata.simulator"
                           :on-change="handleCheckBox"
                />
                <check-box name="Does the lesson require an IDE ?"
                           :meta="checkBoxMetadata.ide"
                           :on-change="handleCheckBox"
                />
            </div>
        </div>
        <div class="form__actions w-full flex justify-end">
            <div class="w-4/5">
                <button-toggle class-name="form__buttons"
                               :names="buttonNames"
                               :handler="handleButtons"
                               :types="buttonTypes"
                />
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckBox from '@/components/ui/CheckBox.vue';
import ButtonToggle from '@/components/ui/ButtonToggle.vue';

export default {
    components: {
        CheckBox,
        ButtonToggle,
    },
    props: {
        onSubmit: {
            type: Function,
            default: () => {},
        },
        onCancel: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            buttonNames: {
                positive: 'create lesson',
                negative: 'cancel',
            },
            buttonTypes: {
                positive: 'submit',
                negative: 'button',
            },
            checkBoxMetadata: {
                simulator: {
                    title: 'simulator',
                },
                ide: {
                    title: 'IDE',
                },
            },
            newLesson: {
                title: '',
                description: '',
                type: '',
                layoutComponents: ['tutor'],
            },
        };
    },
    computed: {
        ...mapGetters('runtimes', {
            runtime: 'currentRuntime',
        }),
    },
    methods: {
        clearLesson() {
            this.newLesson = {
                title: '',
                description: '',
                type: '',
                layoutComponents: ['tutor'],
            };
        },

        handleCheckBox(status, meta) {
            if (status === true) {
                this.newLesson.layoutComponents = [...this.newLesson.layoutComponents, meta.title];
            } else {
                this.newLesson.layoutComponents = this.newLesson.layoutComponents
                    .filter((title) => title !== meta.title);
            }
        },

        getLessonType(layoutComponents) {
            if (layoutComponents.includes('simulator')) {
                return 'interaction';
            }
            if (layoutComponents.includes('IDE')) {
                return 'exercise';
            }
            return 'context';
        },

        async handleButtons(state) {
            if (state === true) {
                const lesson = {
                    ...this.newLesson,
                    runtime: this.runtime,
                    type: this.getLessonType(this.newLesson.layoutComponents),
                };
                await this.onSubmit(lesson);
                this.clearLesson();
            } else {
                await this.onCancel();
                this.clearLesson();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form__buttons{
    width: 100%;
}
</style>
