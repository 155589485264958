<template>
    <form class="form overlay">
        <div class="form__control">
            <label class="form__label">components</label>
            <div class="form__components">
                <check-box
                    name="Does the lesson require a simulator ?"
                    :meta="meta.simulator"
                    :on-change="handleCheckBox"
                    :is-checked="isActive.simulator"
                />
                <check-box
                    name="Does the lesson require an IDE ?"
                    :meta="meta.ide"
                    :on-change="handleCheckBox"
                    :is-checked="isActive.ide"
                />
            </div>
        </div>
        <div class="form__actions">
            <button
                class="button button__base overlay__button"
                type="button"
                @click="onCancel"
            >
                cancel
            </button>
            <button
                class="button button__base overlay__button"
                type="submit"
                @click="changeComponents"
            >
                done
            </button>
        </div>
    </form>
</template>

<script>
import CheckBox from '@/components/ui/CheckBox.vue';

export default {
    components: {
        CheckBox,
    },
    props: {
        onSubmit: {
            type: Function,
            default: () => {},
        },
        onCancel: {
            type: Function,
            default: () => {},
        },
        lesson: {
            type: Object,
            default: () => ({
                title: '',
                description: '',
                type: '',
                layoutComponents: ['tutor'],
            }),
        },
    },

    data() {
        return {
            meta: {
                simulator: {
                    title: 'simulator',
                },
                ide: {
                    title: 'IDE',
                },
            },
            type: this.lesson.type || '',
            layoutComponents: this.lesson.layoutComponents || ['tutor'],
            isActive: {
                simulator: false,
                ide: false,
            },
        };
    },

    watch: {
        lesson: {
            deep: true,
            immediate: true,
            handler(lesson) {
                this.type = lesson.type;
                this.layoutComponents = lesson.layoutComponents;

                if (lesson.layoutComponents.includes('simulator')) {
                    this.isActive.simulator = true;
                }
                if (lesson.layoutComponents.includes('ide')) {
                    this.isActive.ide = true;
                }
            },
        },
    },
    methods: {
        handleCheckBox(status, meta) {
            if (status === true) {
                this.layoutComponents = [...this.layoutComponents, meta.title];
            } else {
                this.layoutComponents = this.layoutComponents
                    .filter((title) => title !== meta.title);
            }
        },

        getLessonType(layoutComponents) {
            if (layoutComponents.includes('simulator')) {
                return 'interaction';
            }
            if (layoutComponents.includes('ide')) {
                return 'exercise';
            }
            return 'context';
        },

        async changeComponents(e) {
            e.preventDefault();
            const payload = {
                type: this.getLessonType(this.layoutComponents),
                layoutComponents: this.layoutComponents.map((i) => i.toLowerCase()),
            };
            await this.onSubmit(payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay{
    width: 100%;
    padding: 0 10px;
}
.overlay__button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}
</style>
