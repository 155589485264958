<template>
    <div class="dashboard">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="onBannerClose"
        />
        <modal :is-modal-open="isModalOpen"
               :toggle-modal="toggleModal"
        >
            <h3 slot="title" class="modal__title">
                Create Lesson
            </h3>
            <div slot="content">
                <div class="flex justify-center">
                    <create-lesson-form :on-submit="onLessonCreate"
                                        :on-cancel="toggleModal"
                    />
                </div>
            </div>
        </modal>
        <nav-bar />
        <div class="editor w-4/5">
            <tab-bar class-name="editor__tabbar">
                <tabs slot="tabs">
                    <tab v-for="(tabbedCourseLesson,
                                 index) in tabbedCourseLessons"
                         :key="index"
                         :name="tabbedCourseLesson.title"
                         :meta="tabbedCourseLesson"
                         :selected="isTabActive(tabbedCourseLesson)"
                         :on-select="onTabbedLessonSelect"
                         :on-close="onTabbedLessonClose"
                    />
                </tabs>
                <div slot="actions" class="tabbar__actions items-center">
                    <div class="ml-1 h-10">
                        <button class="button h-10" @click="pushToCodeRoom">
                            code room
                        </button>
                    </div>
                    <div class="ml-1 h-10">
                        <runtimes :on-runtime-change="onRuntimeChange" />
                    </div>
                    <div v-if="activeCourseLesson" class="editor__settings">
                        <button type="button"
                                class="button h-10 mr-1"
                                @click="togglePopOver"
                        >
                            <font-awesome-icon icon="ellipsis-v"
                                               class="icon"
                            />
                        </button>
                        <pop-over :is-active="popOver.isActive">
                            <lessons-overlay :lesson="activeCourseLesson"
                                             :on-submit="modifyLesson"
                            />
                        </pop-over>
                    </div>
                </div>
            </tab-bar>
            <file-explorer :create-file="createLesson"
                           :handle-create="toggleModal"
                           class-name="editor__explorer"
            >
                <p v-if="activeCourseLesson && startedSaving && isSaving"
                   slot="status"
                   class="status"
                >
                    saving...
                </p>
                <p v-else-if="activeCourseLesson && startedSaving && !isSaving"
                   slot="status"
                   class="status"
                >
                    saved
                </p>
                <draggable slot="content"
                           v-model.trim="courseLessons"
                           group="lessons"
                           drag-class="file__drag"
                           @start="dragging = true"
                           @end="dragging = false"
                >
                    <file v-for="lesson in courseLessons"
                          :key="lesson.id"
                          :file="lesson"
                          :is-active="isLessonActive(lesson)"
                          :delete-file="deleteLesson"
                          :on-click="onLessonClick"
                          :rename-file="renameLesson"
                    />
                </draggable>
            </file-explorer>
            <editor v-if="runtime && courseLessons.length > 0"
                    class-name="editor__main"
                    :default-content="defaultEditorContent"
                    :on-editor-input="onEditorContentChange"
                    :on-save="saveCourseLesson"
                    :on-editor-focus="onEditorFocus"
                    :on-editor-blur="saveCourseLesson"
            />
            <div v-else-if="runtime && !courseLessons.length > 0"
                 class="editor__main"
            >
                <div class="editor__container">
                    <button class="button button__primary bg"
                            @click="toggleModal"
                    >
                        Create your first Lesson
                    </button>
                </div>
            </div>
            <div v-else
                 class="editor__main editor__content"
            >
                <div class="editor__panel">
                    <p class="text-base border border-gray-300
                    rounded-md px-5 py-3 bg-gray-100"
                    >
                        Please select a language to continue
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import NavBar from '@/components/ui/Navbar.vue';
import Banner from '@/components/ui/Banner.vue';
import Runtimes from '@/components/ui/Runtimes.vue';
import FileExplorer from '@/components/textEditor/fileExplorer/FileExplorer.vue';
import File from '@/components/textEditor/fileExplorer/File.vue';
import TabBar from '@/components/textEditor/editor/TabBar.vue';
import Editor from '@/components/textEditor/editor/Editor.vue';
import CreateLessonForm from '@/components/lessons/CreateLessonForm.vue';
import Tabs from '@/components/ui/tabs/Tabs.vue';
import Tab from '@/components/ui/tabs/Tab.vue';
import Modal from '@/components/ui/Modal.vue';
import PopOver from '@/components/ui/PopOver.vue';
import LessonsOverlay from '@/components/lessons/overlays/LessonsOverlay.vue';
import { validateEmptyFields } from '../../../utils';

export default {
    components: {
        NavBar,
        Banner,
        Runtimes,
        FileExplorer,
        draggable,
        File,
        TabBar,
        Editor,
        CreateLessonForm,
        Tabs,
        Tab,
        Modal,
        PopOver,
        LessonsOverlay,
    },

    props: {
        courseId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            defaultEditorContent: '',
            currentContent: '',
            startedSaving: false,
            isSaving: false,
            isModalOpen: false,
            popOver: {
                isActive: false,
            },
            banner: {
                message: '',
                messageType: '',
                isBannerShown: false,
                timeout: null,
            },
            dragging: false,
        };
    },

    computed: {
        ...mapGetters('runtimes', {
            runtime: 'currentRuntime',
        }),
        ...mapGetters('courseLessons', {
            lessons: 'courseLessons',
        }),
        ...mapGetters('courseLessons', [
            'activeCourseLesson',
            'tabbedCourseLessons',
        ]),
        ...mapState('courses', [
            'currentCourse',
        ]),
        ...mapGetters('sockets', ['socketConnectionState']),

        courseLessons: {
            get() {
                return this.lessons;
            },
            set(newArray) {
                this.changeCourseLessonsOrder({
                    lessons: newArray,
                    courseId: this.courseId,
                });
            },
        },
    },

    watch: {
        $route: {
            handler(newRoute) {
                const { params } = newRoute;
                this.setCurrentCourse({ courseId: params.courseId });
            },
            deep: true,
            immediate: true,
        },
    },

    async created() {
        try {
            this.startSocketConnection();
            await this.fetchLessons();
        } catch (ex) {
            this.renderBanner('error', ex.message || 'something went wrong');
        }
    },

    async beforeDestroy() {
        try {
            await this.saveCourseLesson();
            this.clearEditor();
            this.unSetActiveCourseLesson();
            this.unSetTabbedCourseLessons();
            clearTimeout(this.banner.timeout);
        } catch (ex) {
            this.renderBanner('error', ex.message || 'something went wrong');
        }
    },

    methods: {
        ...mapActions('courseLessons', [
            'createCourseLesson',
            'modifyCourseLesson',
            'fetchCourseLessons',
            'deleteCourseLesson',
            'setActiveCourseLesson',
            'setTabbedCourseLesson',
            'unSetActiveCourseLesson',
            'unSetTabbedCourseLessons',
            'unSetTabbedCourseLesson',
            'changeCourseLessonsOrder',
        ]),

        ...mapActions('courses', [
            'modifyCourse',
            'setCurrentCourse',
        ]),

        ...mapActions('sockets', [
            'startSocketConnection',
        ]),
        ...mapActions('content', [
            'submitContent',
        ]),

        renderBanner(type, message) {
            this.banner.messageType = type;
            this.banner.message = message;
            this.banner.isBannerShown = true;
            this.banner.timeout = setTimeout(
                () => { this.banner.isBannerShown = false; },
                3000,
            );
        },

        togglePopOver() {
            this.popOver.isActive = !this.popOver.isActive;
        },

        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
        },

        onBannerClose() {
            this.banner.isBannerShown = false;
            clearTimeout(this.banner.timeout);
        },

        async onLessonCreate(lesson) {
            await this.createLesson(lesson);
            this.toggleModal();
        },

        onEditorFocus() {
            if (!this.runtime) {
                this.renderBanner(
                    'warning',
                    'please select a programming language',
                );
                return;
            }
            if (!this.activeCourseLesson && this.runtime) {
                this.toggleModal();
            }
        },

        pushToCodeRoom() {
            if (this.runtime) {
                this.$router.push(
                    { path: `/courses/coderoom/${this.courseId}` },
                );
                return;
            }
            this.renderBanner('error', 'programming language required');
        },

        async createLesson(lesson) {
            try {
                validateEmptyFields(lesson);
                const payload = {
                    courseId: this.courseId,
                    lesson: { ...lesson },
                };

                const createdLesson = await this.createCourseLesson(payload);
                this.clearEditor();
                this.setActiveCourseLesson(createdLesson.id);
                this.setTabbedCourseLesson(createdLesson.id);
                this.setEditorContent(createdLesson.content[0].content);
            } catch (ex) {
                this.renderBanner('error', ex.message || 'something went wrong');
            }
        },

        async saveCourseLesson() {
            try {
                if (this.socketConnectionState.connectionError) {
                    throw new Error('CMS is experiencing an incident. cannot save your changes');
                }
                if (this.activeCourseLesson && this.currentContent) {
                    this.startedSaving = true;
                    this.isSaving = true;
                    const payload = {
                        courseId: this.courseId,
                        lessonId: this.activeCourseLesson.id,
                        // eslint-disable-next-line no-underscore-dangle
                        contentId: this.activeCourseLesson.content[0]._id,
                        content: this.currentContent,
                    };
                    await this.submitContent(payload);
                    this.isSaving = false;
                }
            } catch (ex) {
                this.isSaving = false;
                this.startedSaving = false;
                this.renderBanner('error', ex.message || 'something went wrong');
            }
        },

        async fetchLessons() {
            await this.fetchCourseLessons(this.courseId);
        },

        async renameLesson(newTitle, file) {
            try {
                const updates = {
                    ...file,
                    title: newTitle,
                };
                const payload = {
                    updates,
                    lessonId: file.id,
                    courseId: this.courseId,
                };
                await this.modifyCourseLesson(payload);
            } catch (ex) {
                this.renderBanner('error', ex.message || 'something went wrong');
            }
        },
        async modifyLesson(updates) {
            try {
                const payload = {
                    updates: {

                        ...this.activeCourseLesson,
                        ...updates,
                    },
                    courseId: this.courseId,
                    lessonId: this.activeCourseLesson.id,
                };
                await this.modifyCourseLesson(payload);
            } catch (ex) {
                this.renderBanner('error', ex.message || 'something went wrong');
            }
        },

        async deleteLesson(lesson) {
            try {
                await this.onTabbedLessonClose(lesson);
                const payload = {
                    lessonId: lesson.id,
                    courseId: this.courseId,
                };
                await this.deleteCourseLesson(payload);
            } catch (ex) {
                this.renderBanner('error', ex.message || 'something went wrong');
            }
        },

        clearEditor() {
            this.currentContent = '<p></p>';
            this.defaultEditorContent = '<p></p>';
        },

        async onLessonClick(lesson) {
            await this.saveCourseLesson();
            this.clearEditor();
            this.setActiveCourseLesson(lesson.id);
            this.setTabbedCourseLesson(lesson.id);
            this.setEditorContent(lesson.content[0].content);
        },

        onEditorContentChange(text) {
            this.currentContent = text;
        },

        setEditorContent(content) {
            this.defaultEditorContent = content || '';
            this.currentContent = content || '';
        },

        async onTabbedLessonSelect(lesson) {
            if (lesson.id === this.activeCourseLesson.id) return;
            await this.saveCourseLesson();
            this.clearEditor();
            this.setActiveCourseLesson(lesson.id);
            if (lesson.content) {
                this.setEditorContent(lesson.content[0].content);
            }
        },

        async onTabbedLessonClose(lesson) {
            if (!this.activeCourseLesson) return;
            if (lesson.id === this.activeCourseLesson.id) {
                await this.saveCourseLesson();
                this.unSetActiveCourseLesson();
                this.clearEditor();

                let previousTab = null;
                let nextTab = null;

                const currentTabIndex = this.tabbedCourseLessons
                    .findIndex(({ id }) => id === lesson.id);

                previousTab = this.tabbedCourseLessons[currentTabIndex - 1];
                nextTab = this.tabbedCourseLessons[currentTabIndex + 1];
                this.unSetTabbedCourseLesson(lesson.id);
                if (previousTab) {
                    this.setActiveCourseLesson(previousTab.id);
                    this.setEditorContent(previousTab.content[0].content);
                    return;
                }

                if (nextTab) {
                    this.setActiveCourseLesson(nextTab.id);
                    this.setEditorContent(nextTab.content[0].content);
                    return;
                }
                return;
            }

            this.unSetTabbedCourseLesson(lesson.id);
        },

        isTabActive(lesson) {
            return (this.activeCourseLesson && lesson) && this.activeCourseLesson.id === lesson.id;
        },

        isLessonActive(lesson) {
            return (this.activeCourseLesson && lesson) && this.activeCourseLesson.id === lesson.id;
        },

        onRuntimeChange() {
            this.unSetActiveCourseLesson();
            this.unSetTabbedCourseLessons();
            this.clearEditor();
        },

    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/mixins";
.dashboard {
    min-height: 100vh;
    @apply w-screen;
    @apply flex;
    @apply justify-center;
    @apply bg-gray-100;
}
.editor {
    @apply mt-16;
    @apply relative;
    min-height: calc(100vh - 4rem);
    @apply bg-white;
    @apply border-r;
    @apply border-l;
    @apply border-solid;
    @apply border-gray-300;
    @apply grid;
    grid-template-columns: 25% 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "explorer tabbar"
        "explorer main";
     @include responsive('tab-land'){
        width: 95vw;
    }
    @include responsive('big-desktop'){
        width: 65vw;
        grid-template-columns: [explorer] 25% [main] 1fr;
    }
}
.editor__tabbar{
    @apply h-12;
    @apply w-full;
    @apply relative;
    @apply grid;
    grid-area: tabbar;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 100%;
}

.tabbar__actions {
    @apply h-full;
    @apply flex;
    @apply items-center;
    @apply relative;
}

.editor__explorer{
    grid-area: explorer;
}

.editor__main{
    @apply grid;
    grid-area: main;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}
.editor__content{
    @apply flex;
    @apply items-center;
    @apply justify-center;
}

.editor__panel{
    grid-area: main;
    @apply h-64;
    @apply w-64;
    @apply bg-white;
    @apply p-4;
    @apply flex;
    @apply items-center;
    @apply justify-around;
    @apply flex-col;
    & >img {
        height: 50%;
    }
    & > p {
        @apply text-sm;
        @apply font-medium;
        @apply text-gray-700;
        @apply text-center;
    }
}

.editor__container{
    @apply flex;
    @apply items-center;
    @apply justify-center;
}

.editor__settings{
    @apply relative;
    @apply h-auto;
    @apply ml-1;
    @apply w-auto;
}

.status{
    @apply font-normal;
    @apply text-sm;
    @apply flex-1;
    @apply text-gray-700;
    @apply ml-3;
}

.form__buttons{
    @apply w-full;
}

.icon{
    @apply text-gray-700;
    @apply text-sm;
}

.file{
    &__drag{
        @apply border-t-2;
        @apply border-solid;
        @apply border-accent;
    }
}

.button {
    @apply inline-flex;
    @apply items-center;
    width: max-content;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-medium;
    @apply rounded-md;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}

</style>
