<template>
    <div
        class="tabbar"
        :class="className"
    >
        <slot name="tabs" />
        <slot name="actions" />
    </div>
</template>

<script>
export default {
    name: 'TabBar',
    props: {
        className: {
            type: String,
            default: '',
        },
    },
};
</script>
<style lang="scss" scoped>
.tabbar {
    &__actions{
        display: flex;
        justify-content: flex-end;
    }
}
</style>
