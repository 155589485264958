<template>
    <form class="form overlay">
        <div class="form__control">
            <label for="title" class="form__label">
                Title
            </label>
            <input v-model.trim="newTitle"
                   class="form__input overlay__input"
                   placeholder="title"
                   type="text"
                   name="title"
            >
        </div>
        <div class="form__actions">
            <button type="button"
                    class="button overlay__button"
                    @click="onCancel"
            >
                cancel
            </button>
            <button type="submit"
                    class="button overlay__button"
                    @click="changeTitle"
            >
                done
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        onSubmit: {
            type: Function,
            default: () => {},
        },
        onCancel: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            newTitle: this.title,
        };
    },
    watch: {
        title: {
            deep: true,
            immediate: true,
            handler(title) {
                this.newTitle = title;
            },
        },
    },
    methods: {
        async changeTitle(e) {
            e.preventDefault();
            await this.onSubmit({ title: this.newTitle });
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay{
    min-width: 250px;
    width: max-content;
    padding: 0 10px;
}
.overlay__button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}
</style>
