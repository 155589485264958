<template>
    <form class="form overlay">
        <div class="form__control">
            <label class="form__label">Description</label>
            <textarea v-model.trim="newDescription"
                      class="form__textarea overlay__description"
                      placeholder="sample description"
            />
        </div>
        <div class="form__actions">
            <button type="button"
                    class="overlay__button"
                    @click="onCancel"
            >
                cancel
            </button>
            <button type="submit"
                    class="overlay__button"
                    @click="changeDescription"
            >
                done
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            default: '',
        },
        onSubmit: {
            type: Function,
            default: () => {},
        },
        onCancel: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            newDescription: this.description,
        };
    },
    watch: {
        description: {
            deep: true,
            immediate: true,
            handler(description) {
                this.newDescription = description;
            },
        },
    },
    methods: {
        async changeDescription(e) {
            e.preventDefault();
            await this.onSubmit({ description: this.newDescription });
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay{
    width: max-content;
    min-width: 250px;
    padding: 0 10px;
}
.overlay__button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}
</style>
