<template>
    <div class="file">
        <div class="file__title"
             :class="{ active: isActive }"
             @click="onFileClick"
             @contextmenu="openMenu"
             @mouseover="showDraggableIcon"
             @mouseout="hideDraggableIcon"
        >
            <div class="file__icon--base"
                 :class="{active: isDraggableIconShown}"
            >
                <font-awesome-icon :icon="['fas', 'grip-vertical']"
                                   class="icon--base"
                />
            </div>
            <div class="file__icon">
                <font-awesome-icon :icon="['fas', 'file-alt']"
                                   class="icon"
                />
            </div>
            <p v-if="!isRename">
                {{ file.title }}
            </p>
            <form v-show="isRename" @submit="rename">
                <input ref="rename"
                       v-model.trim="title"
                       class="file__input"
                       autofocus
                >
            </form>
            <div class="file__menu" :class="{ active: isMenuOpen }">
                <button class="button file__button" @click="toggleRename">
                    rename
                </button>
                <button class="button file__button" @click="remove">
                    delete
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            default: () => ({
                title: '',
                content: [{ content: '' },
                ],
            }),
        },
        deleteFile: {
            type: Function,
            default: () => {},
        },
        onClick: {
            type: Function,
            default: () => {},
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        renameFile: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            isDraggableIconShown: false,
            isMenuOpen: false,
            isRename: false,
            title: this.file.title || '',
        };
    },
    methods: {
        showDraggableIcon() {
            this.isDraggableIconShown = true;
        },
        hideDraggableIcon() {
            this.isDraggableIconShown = false;
        },
        async remove(e) {
            e.stopPropagation();
            await this.deleteFile(this.file);
            this.isMenuOpen = false;
        },
        toggleRename(e) {
            e.stopPropagation();
            this.isRename = !this.isRename;
            this.$refs.rename.focus();
        },

        async rename(e) {
            e.preventDefault();
            await this.renameFile(this.title || this.file.title, this.file);
            this.isRename = false;
            this.isMenuOpen = false;
        },

        async onFileClick(e) {
            e.stopPropagation();
            await this.onClick(this.file);
        },

        openMenu(e) {
            e.stopPropagation();
            e.preventDefault();
            this.isMenuOpen = true;
            document.addEventListener('click', this.closeMenu);
        },

        closeMenu(evt) {
            evt.stopPropagation();
            try {
                if (/file/g.test(evt.target.className)) return;
            } catch (ex) {
                return;
            }
            document.removeEventListener('click', this.closeMenu);
            this.isMenuOpen = false;
            this.isRename = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.file {
    @apply h-auto;
    @apply w-full;
    @apply flex;
    @apply flex-col;
    &__title {
        @apply h-10;
        @apply w-full;
        @apply px-4;
        @apply text-sm;
        @apply text-gray-700;
        @apply font-medium;
        @apply flex;
        @apply items-center;
        @apply relative;
        @apply cursor-pointer;
        & > p {
            text-overflow: ellipsis;
            @apply flex-1;
            @apply overflow-hidden;
            @apply whitespace-no-wrap;
        }
        & > .file__icon {
            color: currentColor;
            @apply text-xs;
        }
        &:hover {
            @apply bg-gray-100;
        }
        &.active {
            @apply bg-gray-100;
            @apply text-accent;
        }
    }
    &__menu {
        width: 80%;
        @apply absolute;
        @apply top-full;
        @apply right-0;
        @apply h-0;
        @apply overflow-hidden;
        @apply bg-gray-100;
        @apply rounded-sm;
        @apply z-10;
        @apply shadow-sm;
        &.active {
            @apply h-auto;
        }
    }
    &__button {
        @apply h-8;
        @apply p-2;
        @apply flex;
        @apply w-full;
        @apply text-xs;
        @apply capitalize;
        @apply font-normal;
        @apply justify-start;
        @apply text-gray-700;
        @apply bg-gray-100;
        &:hover,
        &:focus {
            @apply outline-none;
            @apply bg-gray-200;
        }
    }
    &__icon {
        @apply h-8;
        @apply w-8;
        @apply rounded-md;
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply text-gray-700;
        @apply mr-2;
        @apply transition-all;
        @apply duration-200;
        @apply ease-in-out;
        &--base{
            @apply flex;
            @apply text-gray-700;
            @apply opacity-0;
            &:hover{
                cursor: move;
            }
            &.active{
                @apply opacity-100;
            }
        }
    }
    &__input{
        @apply flex-1;
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply font-medium;
        @apply text-gray-700;
        @apply rounded-md;
        @apply px-2;
        &:hover,
        &:focus {
            @apply outline-none;

        }
    }
}
.icon {
    @apply text-sm;
    &--base{
        @apply text-sm;
    }
}
</style>
