<template>
    <div class="popover" :class="{active: isActive}">
        <div class="popover__arrow top" />
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/mixins";
.popover{
    width: max-content;
    min-width: 200px;
    height: max-content;
    @apply absolute;
    left: 50%;
    display: none;
    transform: translateX(-50%);
    z-index: 50;
    @apply bg-white;
    @apply rounded-md;
    @apply shadow-sm;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    margin-top: 15px;
    @include responsive(tab-land){
        left: unset;
        right: 0;
        transform: unset;
    }
    &.active{
        @apply flex;
        @apply flex-col;
    }
    &__arrow{
        @apply absolute;
        @apply h-4;
        @apply w-4;
        @apply bg-white;
        @apply border-l;
        @apply border-t;
        @apply border-gray-300;
        &.top{
            left: 50%;
            top: -3px;
            transform: rotate(45deg) translateX(-50%);
                @include responsive(tab-land){
                    left: unset;
                    right: 0;
                }
        }
    }
    &__container{
        @apply w-full;
        height: max-content;
        margin-top: 10px;
        padding: 10px 0;
        border-bottom: 1px solid var(--color-grey-01);
    }
    &__button{
        @apply w-full;
        height: 30px;
        border-radius: unset;
        @apply text-sm;
        justify-content: flex-start;
        padding: unset;
        @apply bg-white;
        @apply text-gray-700;
        padding-left: 10px;
    }
}
</style>
