<template>
    <div :class="className">
        <ckeditor
            v-model.trim="content"
            :editor="editor"
            :config="options"
            @input="onContent"
            @focus="onEditorFocus"
            @blur="onEditorBlur"
        />
    </div>
</template>

<script>
import CKEeditor from '@ckeditor/ckeditor5-vue';
import Classiceditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';

import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';

import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';

import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';

import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import List from '@ckeditor/ckeditor5-list/src/list';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import AutoSave from '@ckeditor/ckeditor5-autosave/src/autosave';

import { uploadImage } from '../../../utils/api/http/assets/assets';

function CustomUploadAdapter(loader) {
    this.loader = loader;
    this.upload = async () => {
        const file = await loader.file;
        const url = await uploadImage(file);
        return { default: url };
    };
    this.abort = () => {};
}

export default {
    name: 'Editor',
    components: {
        ckeditor: CKEeditor.component,
    },
    props: {
        className: {
            type: String,
            default: '',
        },
        defaultContent: {
            type: String,
            default: '',
        },
        onEditorInput: {
            type: Function,
            default: () => {},
        },
        onEditorFocus: {
            type: Function,
            default: () => {},
        },
        onEditorBlur: {
            type: Function,
            default: () => {},
        },
        onSave: {
            type: Function,
            default: () => {},
        },
        onDefaultChange: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            editor: Classiceditor,
            content: this.defaultContent,
            defaultWindowKeyEvent: null,
            options: {

                placeholder: 'Tell your story ...',
                plugins: [
                    EssentialsPlugin,
                    UploadAdapter,
                    Autoformat,
                    BoldPlugin,
                    ItalicPlugin,
                    UnderlinePlugin,
                    BlockQuote,
                    Highlight,
                    Heading,
                    EasyImage,
                    Image,
                    CodeBlock,
                    ImageStyle,
                    ImageToolbar,
                    ImageCaption,
                    MediaEmbed,
                    HorizontalLine,
                    ImageUpload,
                    Indent,
                    Table,
                    Code,
                    TableToolbar,
                    LinkPlugin,
                    List,
                    ParagraphPlugin,
                    AutoSave,
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'horizontalLine',
                        'highlight',
                        '|',
                        'link',
                        'code',
                        'codeBlock',
                        'blockQuote',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'imageupload',
                        'mediaEmbed',
                        'insertTable',
                        '|',
                        'undo',
                        'redo',
                    ],
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                    ],
                },
                image: {
                    toolbar: [
                        'imageStyle:full',
                        'imageStyle:side',
                        '|',
                        'imageTextAlternative',
                    ],
                },
                highlight: {
                    options: [
                        {
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker',
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker',
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen',
                        },
                    ],
                },
                extraPlugins: [
                    this.UploadAdapter,
                ],
                mediaEmbed: {
                    providers: [{
                        name: 'iframely',
                        url: /.+/,
                        html: (match) => {
                            const IFRAME_SRC = '//cdn.iframe.ly/api/iframe';
                            const API_KEY = 'dbf7358aa93aecea31f85b';
                            const url = match[0];

                            const iframeUrl = `${IFRAME_SRC}?app=1&api_key=${API_KEY}&url=${encodeURIComponent(url)}`;

                            return (
                                `<iframe id="iframe" class="iframe" src="${iframeUrl}"`
                                        + 'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>'
                                    + '</iframe>'

                            );
                        },
                    }],
                },
                autosave: {
                    save: (editor) => this.onSave(editor.getData()),
                },
            },
        };
    },

    watch: {
        defaultContent: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.content = newVal;
            },
        },
    },

    created() {
        document.addEventListener('keydown', this.onSaveEvent);
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.onSaveEvent);
    },

    methods: {
        async onSaveEvent(e) {
            const event = e;
            if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
                event.preventDefault();
                await this.onSave();
            }
        },

        onContent(text) {
            this.onEditorInput(text);
        },

        UploadAdapter(editor) {
            // eslint-disable-next-line no-param-reassign
            editor.plugins
                .get('FileRepository')
                .createUploadAdapter = (loader) => new CustomUploadAdapter(loader);
        },
    },

};
</script>
