<template>
    <div class="explorer" :class="className">
        <div class="header">
            <p class="header__title">
                {{ name }}
            </p>
            <slot name="status" />
            <div class="header__actions">
                <button :disabled="!runtime"
                        class="button explorer__button"
                        @click="openCreate"
                >
                    create
                </button>
            </div>
        </div>
        <div class="explorer__content">
            <slot name="content" />
            <form class="explorer__create"
                  :class="{ active: isCreateOpen }"
                  @submit="createLesson"
            >
                <div class="explorer__icon">
                    <font-awesome-icon :icon="['far', 'file']"
                                       class="icon"
                    />
                </div>
                <input ref="title"
                       v-model.trim="title"
                       class="explorer__input"
                       autofocus
                >
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        className: {
            type: String,
            default: '',
        },
        createFile: {
            type: Function,
            default: () => {},
        },
        handleCreate: {
            type: Function,
            default: null,
        },
        name: {
            type: String,
            default: 'Lessons',
        },

    },
    data() {
        return {
            isCreateOpen: false,
            title: '',
        };
    },
    computed: {
        ...mapGetters('runtimes', {
            runtime: 'currentRuntime',
        }),
    },
    methods: {
        openCreate(e) {
            e.preventDefault();
            if (this.handleCreate) {
                this.handleCreate();
                return;
            }
            this.isCreateOpen = true;
            this.$refs.title.focus();
            setTimeout(() => {
                document.addEventListener('click', this.closeCreate);
            }, 100);
        },
        closeCreate(evt) {
            try {
                if (/input/g.test(evt.target.className)) return;
            } catch (ex) {
                return;
            }
            document.removeEventListener('click', this.closeCreate);
            this.isCreateOpen = false;
        },

        async clearTitle() {
            this.title = '';
        },
        async createLesson(e) {
            e.preventDefault();
            document.removeEventListener('click', this.closeCreate);
            this.isCreateOpen = false;
            await this.createFile(this.title);
            this.clearTitle();
        },
    },

};
</script>

<style lang="scss" scoped>
.explorer {
    @apply h-full;
    @apply flex;
    @apply flex-col;
    @apply border-r;
    @apply border-solid;
    @apply border-gray-300;
}
.explorer__create {
    width: calc(100% - 15px);
    height: 0;
    @apply h-0;
    @apply ml-3;
    @apply flex;
    @apply items-center;
    @apply bg-white;
    @apply justify-between;
    @apply rounded-sm;
    @apply overflow-hidden;
    &.active {
        @apply h-12;
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
    }
}
.explorer__icon {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply h-full;
    @apply w-12;
    @apply text-gray-700;
    @apply border-r;
    @apply border-solid;
    @apply border-gray-300;
    & > .icon {
        @apply text-sm;
    }
}
.explorer__input {
    flex: 1;
    border: none;
    background: transparent;
    height: 40px;
    font-family: var(--font-primary);
    font-size: 1.5rem;
    color: var(--color-text);
    padding: 0px 5px;
    &:hover,
    &:focus {
        outline: none;
    }
}
.explorer__button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}
.expolorer__content {
    @apply flex-1;
}
.explorer__card {
    @apply flex;
    @apply h-full;
    @apply w-full;
    @apply items-center;
    @apply justify-center;
    &--content {
        width: 240px;
        height: 240px;
        border: 1px solid var(--color-grey-01);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--white);
        & > p {
            @apply text-base;
            font-weight: var(--fontWeight-secondary);
            color: var(--color-grey-05);
        }
        & > img {
            width: 60%;
        }
    }
}

.header {
    @apply h-12;
    @apply w-full;
    @apply justify-between;
    @apply items-center;
    @apply px-4;
    @apply flex;
    &__actions {
        @apply flex;
        @apply h-full;
        @apply items-center;
    }
    &__title {
       @apply text-sm;
       @apply text-gray-700;
       @apply font-medium;
    }
}
</style>
