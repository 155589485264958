<template>
    <div class="tab" :class="{ active: selected }">
        <p class="tab__title" @click="onTabOpen">
            {{ name }}
        </p>
        <button class="button tab__button" @click="onTabClose">
            <font-awesome-icon icon="times" class="icon" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            required: true,
            type: String,
        },
        selected: {
            default: false,
            type: Boolean,
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        meta: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    mounted() {
        this.isActive = this.selected;
    },
    methods: {
        async onTabOpen(e) {
            e.stopPropagation();
            await this.onSelect(this.meta);
        },
        async onTabClose(e) {
            e.stopPropagation();
            await this.onClose(this.meta);
        },
    },
};
</script>

<style lang="scss">
.tab {
    @apply h-full;
    @apply w-32;
    @apply inline-flex;
    @apply justify-between;
    @apply items-center;
    @apply border-r;
    @apply border-solid;
    @apply border-gray-300;
    @apply pl-2;
    &.active {
        @apply cursor-pointer;
        @apply relative;
        @apply bg-white;
        @apply h-full;
        @apply bg-gray-100;
        @apply border-b;
        @apply border-solid;
        @apply border-white;
    }
    &__title{
        @apply whitespace-no-wrap;
        @apply overflow-hidden;
        text-overflow: ellipsis;
        @apply font-medium;
        @apply text-xs;
        @apply text-gray-700;
        @apply cursor-pointer;
    }
    &__content {
        @apply w-full;
    }
    &__button {
        @apply h-full;
        @apply w-8;
        @apply p-0;
        @apply bg-transparent;
        @apply rounded-none;
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply text-gray-700;
        @apply cursor-pointer;
        @apply text-xs;
        &:hover,
        &:focus {
            @apply text-accent;
            @apply outline-none;
        }
    }
}
</style>
